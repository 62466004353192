<template>
  <div class="hello">
    <van-button @click="ceshi">测试B</van-button>
  </div>
</template>

<script>
export default {
  name: 'TestB',
  props: {
    msg: String
  },
  methods:{
    ceshi(){
      console.log(window)
      window.gdt('track', 'PURCHASE',{'key1': 'value1', 'key2': 'value2'});// 付费
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
