<template>
  <!-- <div class="hello" ref="overlayContainer" > -->
  <div class="hello" ref="overlayContainer">
    <div class="GradientA">
      <div class="Gradient" />
      <div class="topTop">
        <p style="font-size: 1.5rem; color: #fff">风茂权益大厅</p>
      </div>
      <div class="banner">
        <div class="image-container" style="z-index: 99">
          <img src="../assets/底色.png" alt="" class="image-container-i" />
          <div class="text-overlay">
            <p class="img_text_t">视听权益会员卡</p>
            <div class="img_text_b">
              <div class="img_text_b_l">月月任我换</div>
              <div class="img_text_b_r" @click="handleRule">规则</div>
            </div>
            <div class="img_text_i">
              <div class="img_text_img">
                <img src="../assets/组 3.png" alt="" />
              </div>
            </div>
          </div>
          <!--  -->
          <div class="img_text_phone">
            <div class="img_text_p_t">
              <p>权益领取手机号</p>
            </div>

            <van-cell-group inset>
              <van-field
                v-model="phone"
                placeholder="请输入手机号码查询"
                style="border-bottom: 1px solid rgb(221, 221, 221)"
                class="custom-field"
                type="tel"
                :error-message="phoneError"
                @input="validatePhoneNumber"
                @blur="placePhone"
              />
            </van-cell-group>
            <div class="tuBiao2" v-if="!this.phone">
              <img src="../assets/图标(2).png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <van-overlay :show="show" @click="handleOverlayClick">
        <div class="wrapper" @click="toggleOverlay">
          <div class="block">{{ this.isText }}</div>
        </div>
      </van-overlay>

      <!-- 视频会员 -->
      <div class="videoMember">
        <div class="vM_t">
          <div class="vM_t_l">
            <img src="../assets/图标(1).png" alt="" class="vM_t_l_i" />
            <span style="margin-left: 10px"
              >视频会员&nbsp;<span style="color: red">月月N</span
              >&nbsp;选一</span
            >
          </div>
          <div class="vM_t_r">
            <span @click="handleRule">
              <span>了解详情</span><van-icon name="arrow" />
            </span>
          </div>
        </div>
        <!-- <div class="itemW" v-if="!this.isShow"> -->
        <div class="itemW">
          <div v-for="item in productList" :key="item.id" class="itemQ">
            <div class="colC">
              <img
                :src="item.icon"
                alt=""
                style="width: 50px; height: 50px; margin-top: 5px"
              /><br />
              <span>{{ item.name }}</span>
              <div v-if="isReceive" class="vM_b_c_t">
                <img src="../assets/底色(1).png" alt="" />
                <div class="vM_b_c_t_t" @click="handlePeoduct(item)">领取</div>
              </div>
              <div v-else class="vM_b_c_tQ">
                <div class="equity_b_i_t2">
                  <span v-if="item.receiveStatus">已领取</span>
                  <span v-else>领取</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="itemWS" v-else>
          <div>请先填写手机号</div>
        </div> -->
      </div>

      <!-- 底部 -->
      <div class="equity">
        <div class="equity_t">
          <img src="../assets/图标.png" alt="" />
          <span style="margin-left: 10px"
            >每月领取&nbsp;<span style="color: red">5元</span>&nbsp;权益</span
          >
        </div>
        <!-- <div class="itemS" v-if="!this.isShow"> -->
        <div class="itemS">
          <div v-for="item in items" :key="item.id" class="item">
            <div class="equity_b" style="display: inline-block">
              <div class="equity_b_c">
                <img src="../assets/组合.png" alt="" />
                <div class="equity_b_c_t">
                  <div style="textalign: center">{{ item.name }}</div>
                </div>
                <div class="equity_b_c_c">
                  {{ item.lookPrice }}<span>元</span>
                </div>
                <div class="equity_b_c_b">售价{{ item.price }}元</div>
                <div class="equity_b_i">
                  <div v-if="!isReceive2" class="equity_b_i_t2">
                    <span v-if="item.receiveStatus">已领取</span>
                    <span v-else>领取</span>
                  </div>
                  <div v-else class="equity_b_i_tT">
                    <span @click="handlePeoduct(item)" style="color: red"
                      >领取</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="itemWS" v-else>
          <div>请先填写手机号</div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { Field, CellGroup, Overlay } from "vant";
import axios from "axios";
// import axiosA from "../axios"
export default {
  name: "RightsHall",
  components: {
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [Overlay.name]: Overlay,
  },
  data() {
    return {
      isReceive: true,
      phone: "",
      phoneA: "",
      phoneError: "",
      show: false,
      items: [],
      productList: [],
      // isShow: true,
      isText: "",
      isReceive2: true,
    };
  },
  props: {
    msg: String,
  },
  mounted() {
    // if (this.productList.length < 1) {
    //   this.isShow = true;
    // } else {
    //   this.isShow = false;
    // }
    this.getList();
  },
  methods: {
    // 获取列表
    getList() {
      axios
        .get(
          `https://h5-api.great6666.com/api/user/receive-ward/productList?phone`
        )
        .then((res) => {
          // this.isShow = false;
          this.items = res.data.data.couponList;
          this.productList = res.data.data.productList;
          this.productList.forEach((item) => {
            item.type = 1;
          });
          this.items.forEach((item) => {
            item.type = 2;
          });
          if (this.productList.some((item) => item.receiveStatus === 1)) {
            // this.productList.forEach((item) => {
            //   item.receiveStatus = 1;
            // });
            this.isReceive = false;
          } else {
            this.isReceive = true;
          }
          if (this.items.some((item) => item.receiveStatus === 1)) {
            // this.productList.forEach((item) => {
            //   item.receiveStatus = 1;
            // });
            this.isReceive2 = false;
          } else {
            this.isReceive2 = true;
          }
        });
    },
    // handleTap() {
    //   console.log(this.productList.length);
    //   if (this.productList.length < 1) {
    //     setTimeout(() => {
    //       if (this.show) {
    //         this.show = false;
    //       }
    //     }, 2000);
    //   } else if (this.show) {
    //     this.show = false;
    //   }
    // },
    handleOverlayClick() {
      this.show = false;
    },
    toggleOverlay() {
      this.show = false;
    },
    validatePhoneNumber() {
      // this.phone = this.phone.replace(/\D/g, "");
      // // 在第三位和第七位添加分隔符
      // if (this.phone.length >= 3 && this.phone.length < 7) {
      //   this.phone = this.phone.replace(/(\d{3})(\d+)/, "$1 $2");
      // } else if (this.phone.length >= 7) {
      //   this.phone = this.phone.replace(/(\d{3})(\d{4})(\d+)/, "$1 $2 $3");
      // }
      if (this.phone.length == 11) {
        this.placePhone();
      }
    },
    placePhone() {
      // this.phoneA = this.phone.replace(/\s+/g, "");
      this.phoneA = this.phone;
      if (this.phoneA.length == 11) {
        if (!/^1[3-9]\d{9}$/.test(this.phoneA)) {
          // Notify({ type: "danger", message: "请输入正确的手机号码" });
          this.show = true;
          // this.isShow = true;
          this.isText = "请输入正确的手机号码";
          setTimeout(() => {
            if (this.show) {
              this.show = false;
            }
          }, 3000);
        } else {
          axios
            .get(
              `https://h5-api.great6666.com/api/user/receive-ward/productList?phone=${this.phoneA}`
            )
            .then((res) => {
              // this.isShow = false;
              this.items = res.data.data.couponList;
              this.productList = res.data.data.productList;
              this.productList.forEach((item) => {
                item.type = 1;
              });
              this.items.forEach((item) => {
                item.type = 2;
              });
              if (this.productList.some((item) => item.receiveStatus === 1)) {
                // this.productList.forEach((item) => {
                //   item.receiveStatus = 1;
                // });
                this.isReceive = false;
              } else {
                this.isReceive = true;
              }
              if (this.items.some((item) => item.receiveStatus === 1)) {
                // this.productList.forEach((item) => {
                //   item.receiveStatus = 1;
                // });
                this.isReceive2 = false;
              } else {
                this.isReceive2 = true;
              }
            });
        }
      } else {
        // Notify({ type: "danger", message: "请输入正确的手机号码" });
        this.show = true;
        // this.isShow = true;
        this.isText = "请输入正确的手机号码";
        setTimeout(() => {
          if (this.show) {
            this.show = false;
          }
        }, 3000);
        this.getList();
        // this.productList = [];
        // this.items = [];
      }
    },
    handleRule() {
      this.$router.push({ name: "RuleCom" });
    },
    handlePeoduct(item) {
      axios
        .post("https://h5-api.great6666.com/api/user/receive-ward/add", {
          phone: this.phoneA,
          productId: item.id,
          type: item.type,
        })
        .then((res) => {
          if (res.data.code != 200) {
            // Notify({ type: "danger", message: res.data.message });
            this.show = true;
            this.isText = res.data.message;
            setTimeout(() => {
              if (this.show) {
                this.show = false;
              }
            }, 3000);
          } else {
            // Notify({ type: "primary", message: "领取成功" });
            this.placePhone();
            this.show = true;
            this.isText = "领取成功";
            setTimeout(() => {
              if (this.show) {
                this.show = false;
              }
            }, 3000);
          }
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hello {
  margin-top: 20px;
  padding: 0 8px;
  background-color: rgb(238, 238, 238);
}
.GradientA {
  position: relative;
  height: 100%;
}
.Gradient {
  position: absolute;
  top: -3%;
  left: -5%;
  padding: 0 17px;
  z-index: 2;
  width: 100%;
  height: 260px;
  background: linear-gradient(to bottom, rgb(22, 119, 255), rgb(238, 238, 238));
}
.topTop {
  margin-bottom: 15px;
  text-align: left;
  position: relative;
  z-index: 99;
}
.image-container {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
}
.image-container-i {
  width: 100%;
  height: 100%;
}
.text-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 63%;
  left: 47%;
  transform: translate(-50%, -50%);
  padding: 10px;
  text-align: left;
}
.img_text_t {
  font-size: 2rem;
  margin-left: 2.5rem;
  color: #e9d0a6;
  font-weight: 600;
}
.img_text_b {
  /* margin-top: 20px; */

  margin: 20px 0.5rem 0 2.5rem;
  display: flex;
}
.img_text_b_l {
  display: inline-block;
  color: rgba(233, 208, 166, 0.75);
  font-size: 1.8rem;
  flex: 1;
}
.img_text_b_r {
  display: inline-block;
  /* width: 80px; */
  /* height: 30px; */
  background-color: rgba(255, 255, 255, 0.75);
  flex: 0.4;
  border-radius: 2rem;
  text-align: center;
  line-height: 34px;
}
.img_text_i {
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.img_text_phone {
  width: 100%;
  height: 7rem;
  background-color: #fff;
  color: rgba(51, 51, 51, 1);

  position: absolute;
  top: 90%;
  border-radius: 20px;
  padding-top: 20px;
}
.img_text_p_t {
  font-size: 1rem;
  font-weight: 700;
  /* border-top: 1px dashed rgb(137, 138, 138); */
  text-align: left;
  padding-left: 16px;
}
.van-cell {
  padding: 20px 16px 10px 0;
  position: relative;
}
.van-hairline--top-bottom::after {
  border: none;
}
/* .custom-field .van-field__control::-webkit-input-placeholder{
  color: red;
  font-size: 1.5rem;
} */
.tuBiao2 {
  position: absolute;
  top: 55%;
  left: 58%;
}

/* 视频会员 */
.videoMember {
  background-color: #fff;
  width: 100%;
  min-height: 19rem;
  margin-top: 7.5rem;
  border-radius: 2rem;
}
.vM_t div {
  display: inline-block;
}
.vM_t {
  display: flex;
  padding-top: 20px;
  /* height: 28px; */
  /* line-height: 28px; */
  align-items: flex-start;
}
.vM_t_l {
  flex: 1.5;
  text-align: left;
  margin-left: 16px;
  font-size: 16px;
  vertical-align: middle;
}
.vM_t_l_i {
  position: relative;
  top: 50%;
  transform: translateY(20%);
}
.vM_t_l span {
  font-size: 1rem;
  font-weight: 700;
}
.vM_t_r {
  flex: 0.5;
  text-align: right;
  margin-right: 16px;
  align-items: center;
  color: rgb(136, 148, 168);
}
.vM_t_r span {
  font-size: 10px;
}
.vM_b {
  margin: 10px 16px 0 16px;
}
.vM_b .van-col--8 {
  border: 1px solid rgb(208, 203, 193);
}

.vM_b_c_t {
  position: relative;
  margin: 8px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.vM_b_c_tQ {
  position: relative;
  margin: 8px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.vM_b_c_t_t {
  position: absolute;
  color: red;
}

/* 已领取 */
.Received {
  width: 5rem;
  height: 1.3rem;
  background-color: rgb(221, 221, 221);
  border-radius: 10px;
  margin: 5px auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Received span {
  color: rgb(250, 250, 250);
}

/* 新 */
.rowR {
  display: flex;
  justify-content: space-between;
  color: rgba(51, 51, 51, 1);
  font-size: 12px;
}
.rowR span {
  font-weight: 700;
}
.colC {
  flex: 0 0 30%;
  border: 1px solid rgb(204, 196, 180);
  border-radius: 10px;
  padding-top: 5px;
}

/* 权益领取 */
.equity {
  margin-top: 0.5rem;
  background-color: #fff;
  min-height: 14rem;
  border-radius: 20px;
}
.itemS {
  /* display: flex;
  justify-content: space-between; */
  display: flex;
  flex-wrap: wrap;
  margin-left: 3.5%;
  margin-right: 4%;
  margin-top: 10px;
  color: rgba(51, 51, 51, 1);
  font-size: 12px;
  font-weight: 700;
  padding-bottom: 2rem;
}
.equity .item {
  /* flex: 1;
  display: inline-block; */
  width: 31%;
  margin: 10px 0 0 2%;
}

/* 权益1 */
.itemW {
  display: flex;
  flex-wrap: wrap;
  margin-left: 3.5%;
  margin-right: 4%;
  margin-top: 10px;
  color: rgba(51, 51, 51, 1);
  font-size: 12px;
  font-weight: 700;
  padding-bottom: 2rem;
  /* gap: 20px; */
  /* justify-content: space-between; */
}
.itemW .itemQ {
  /* flex: 1; */
  width: 31%;
  margin: 10px 0 0 2%;
  /* margin: 10px 0 0 3.3%; */
  display: inline-block;
}
.itemQ span {
  display: inline-block;
  margin: 3px 0;
}

.equity_t {
  padding-top: 20px;
  text-align: left;
  margin-left: 16px;
  display: flex;
  align-items: flex-start;
}
.equity_t span {
  font-size: 1rem;
  font-weight: 700;
}
.equity_b {
  /* margin: 15px 0 0 16px; */
  /* margin: 15px 0 0 0; */
  display: flex;
  text-align: left;
}
.equity_b_c {
  flex: 1;
  position: relative;
}
.equity_b_c_t {
  width: 100%;
  position: absolute;
  top: 5%;
  /* left: 20%; */
  font-size: 12px;
  color: rgb(95, 80, 82);
  font-weight: 700;
  text-align: center;
}
.equity_b_c_c {
  position: absolute;
  top: 20%;
  left: 32%;
  font-size: 2.5rem;
  color: red;
  font-weight: 700;
}
.equity_b_c_c span {
  font-weight: normal;
  font-size: 1rem;
}
.equity_b_c_b {
  position: absolute;
  top: 66%;
  left: 26%;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
}
.equity_b_i {
  position: absolute;
  top: 80%;
  left: 15%;
  /* background-color: aqua; */
}
.equity_b_i_t {
  position: relative;
}
/* 已领取 */
.equity_b_i_t2 {
  width: 5rem;
  height: 1.3rem;
  background-color: rgb(221, 221, 221);
  border-radius: 10px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.equity_b_i_t2 span {
  color: rgb(250, 250, 250);
  font-size: 12px;
}
/* 未领取 */
.equity_b_i_tT {
  width: 5rem;
  height: 1.3rem;
  background-color: rgb(255, 221, 158);
  border-radius: 10px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.equity_b_i_tT span {
  color: rgb(250, 250, 250);
  font-size: 12px;
}

.equity_b_i_b {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: red;
  font-weight: 600;
}

/* 手机号输入框 */
::v-deep .custom-field input[type="tel"] {
  /* color: red; */
  font-size: 1.5rem;
  font-weight: 700;
}
/* input.van-field__control{
  color: red;
} */

/* 遮罩层大小 */
.van-overlay {
  width: 60%;
  /* width: 300px;  */
  height: 10%;
  left: 20%;
  top: 50%;
  transform: "translate(-50%, -50%)";
  border-radius: 20px;
  /* background-color: rgb(91, 91, 91); */
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #fff;
}
.itemWS {
  font-weight: 600;
  font-size: 30px;
  color: rgb(200, 201, 201);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10rem;
}
</style>
