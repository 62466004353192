<!--  -->
<template>
  <div class="ruleCom">
    <div class="jumpHello" >
      <div class="jumpHello_1" >
        <div @click="handleHello">
          <van-icon name="arrow-left" /><span>规则</span>
        </div>
        
      </div>
      
    </div>
    <div class="rule1">
      <div class="rule1_t">
        <img src="../assets/图标(1).png" alt="" class="rule1_t_i" />
        <span style="margin-left: 10px"
          >视频会员&nbsp;<span style="color: red">月月N</span>&nbsp;选一</span
        >
      </div>
      <div class="rule1_b">
        <div>
          1、视听权益会员包，办理成功即从支付宝账号内扣费，每月自动续费。
        </div>
        <div>
          2、当您开通视听权益包后,即可免费领取爱奇艺、腾讯视频、芒果TV、优酷视频、哗哩哗哩、喜马拉雅、QQ音乐、网易云音乐等热门视听会员权益中的1种。权益内容为特定会员月卡，且会不定期增加。
        </div>
        <div>
          3、领取后,会自动发放至您绑定的手机号码,请确保绑定的手机号码是该权益产品的注册账号。
        </div>
        <div>
          4、退订方式:前往支付宝-“我的”-设置-支付设置-免密支付/自动扣款-点击“风茂权益大厅”，选择关闭服务即可退订
        </div>
      </div>
    </div>

    <div class="rule2">
      <div class="rule2_t">
        <img src="../assets/图标.png" alt="" class="rule2_t_i" />
        <span style="margin-left: 10px"
          >每月领取&nbsp;<span style="color: red">5元</span>&nbsp;权益</span
        >
      </div>
      <div class="rule2_b">
        <div>1、当您开通视听权益包后,每月可免费获得―次特殊福利。</div>
        <div>2、请在有效期内选择领取权益,逾期未领取不补发。</div>
        <div>3、领取的优惠券可在【我的-我的卡券】中找到</div>
        <div>4、在充值话费时,可勾选当前的可用优惠券进行现金抵扣</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RuleCom",
  components: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {
    handleHello() {
      this.$router.push({ name: "RightsHall" });
    },
  },
};
</script>
<style  scoped>
.ruleCom{
  /* height: 100vh; */
  margin-top: 20px;
  padding: 0 8px;
  background-color: rgb(238,238,238);
}
.jumpHello{
  height: 6rem;
  background-color: #fff;
  margin-left: -16px;
  margin-right: -16px;
  margin-top: -20px;
  text-align: left;
  
}
.jumpHello_1{
  height: 4rem;
  font-size: 1.8rem;
  display: flex;
  align-items: center;
  padding-top: 2rem;
  margin-left: 15px;
}
.rule1 {
  width: 100%;
  height: 25rem;
  background-color: #fff;
  border-radius: 20px;
  margin-top: 1rem;
}
.rule1_t {
  text-align: left;
  font-size: 20px;
  vertical-align: middle;
  padding-top: 20px;
  margin-left: 20px;
}
.rule1_t_i {
  position: relative;
  top: 50%;
  transform: translateY(10%);
}
.rule1_t span {
  font-size: 1.5rem;
  font-weight: 700;
}
.rule1_b {
  margin: 25px 20px;
  text-align: left;
  font-size: 12px;
  font-weight: 700;
  background-color: rgb(238, 238, 238);
  border-radius: 20px;
}
.rule1_b div {
  /* margin: 10px 0; */
  padding: 10px;
}

.rule2 {
  margin-top: 1rem;
  background-color: #fff;
  height: 18rem;
  border-radius: 20px;
}
.rule2_t {
  padding-top: 20px;
  text-align: left;
  margin-left: 20px;
  font-size: 20px;
}
.rule2_t_i {
  position: relative;
  top: 50%;
  transform: translateY(10%);
}
.rule2_t span {
  font-size: 1.5rem;
  font-weight: 700;
}
.rule2_b {
  margin: 25px 20px;
  text-align: left;
  font-size: 12px;
  font-weight: 700;
  background-color: rgb(238, 238, 238);
  border-radius: 20px;
}
.rule2_b div {
  /* margin: 10px 0; */
  padding: 10px;
}
</style>