<template>
  <div style="margin-top: 50px">
    <div class="AAA2">
        <p class="AAA3">
            <van-button url="http://h5-test.great6666.com/LandingPage?clCode=til02ghp">莱约1</van-button>
        </p>
        <p class="AAA3">
            <van-button url="http://h5-test.great6666.com/LandingPage?clCode=av4k9deu">莱约3</van-button>
        </p>
        <p class="AAA3">
            <van-button url="http://h5-test.great6666.com/LandingPage?clCode=v28oos1d">风茂1</van-button>
        </p>
        <p class="AAA3">
            <van-button url="http://h5-test.great6666.com/LandingPage?clCode=mb47qp2n">风茂2</van-button>
        </p>
        <p class="AAA3">
            <van-button url="http://h5-test.great6666.com/LandingPage?clCode=vxodnrl1">字节码1</van-button>
        </p>
        <p class="AAA3">
            <van-button url="http://h5-test.great6666.com/LandingPage?clCode=4pi54see">莱约4</van-button>
        </p>
        <p class="AAA3">
            <van-button url="http://h5-test.great6666.com/LandingPage?clCode=oimzxiud">风沙2</van-button>
        </p>
        <p class="AAA3">
            <van-button url="http://h5-test.great6666.com/LandingPage?clCode=9z3rd32k">风茂3</van-button>
        </p>
        <p class="AAA3">
            <van-button url="http://h5-test.great6666.com/LandingPage?clCode=sqy33wdq">风沙1</van-button>
        </p>
        <p class="AAA3">
            <van-button url="http://h5-test.great6666.com/LandingPage?clCode=becd6v3a">莱集1</van-button>
        </p>
        <p class="AAA3">
            <van-button url="http://h5-test.great6666.com/LandingPage?clCode=rrq4g56s">大碗1</van-button>
        </p>
        <p class="AAA3">
            <van-button url="http://h5-test.great6666.com/LandingPage?clCode=33s6p1d8">风茂4</van-button>
        </p>
        <p class="AAA3">
            <van-button url="http://h5-test.great6666.com/LandingPage?clCode=r24ui4na">风茂5</van-button>
        </p>
        <p class="AAA3">
            <van-button url="http://h5-test.great6666.com/LandingPage?clCode=4eoz06b6">风沙3</van-button>
        </p>
        <p class="AAA3">
            <van-button url="http://h5-test.great6666.com/LandingPage?clCode=5cs5tzhx">莱约2</van-button>
        </p>
        <p class="AAA3">
            <van-button url="http://h5-test.great6666.com/LandingPage?clCode=cu4faig3">字节码2</van-button>
        </p>
        <p class="AAA3">
            <van-button url="http://h5-test.great6666.com/LandingPage?clCode=aodxhhj8">风沙4</van-button>
        </p>
        <p class="AAA3">
            <van-button url="http://h5-test.great6666.com/LandingPage?clCode=hvd8wpmc">大碗2</van-button>
        </p>
        <p class="AAA3">
            <van-button url="http://h5-test.great6666.com/LandingPage?clCode=msuemn5c">大碗3</van-button>
        </p>
        <p class="AAA3">
            <van-button url="http://h5-test.great6666.com/LandingPage?clCode=arfsc722">大碗4</van-button>
        </p>
    </div>
  </div>
</template>

<script>
export default {
    name:"AAA",
    methods: {
        // goLandingPage(){
        //     const button = this.$refs.button1.textContent;
        //     console.log(button)
        // }
    }
}
</script>

<style scoped>
.AAA2{
    /* display: flex;
    text-align: center;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-evenly */
}
.AAA3{
    /* width: 80%;
    display: flex;
    justify-content: space-evenly;
    align-items: center; */
    margin: 10px 0;
}

</style>