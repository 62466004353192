<template>
    <div class="" style="width: 100%; height: 100%">
      <p>redirecting to Alipay...</p>
      <button id="jumpButton" @click="goGo">跳转到 App Store</button>
      <!-- <a :href="alipayLink">打开qq</a> -->
    </div>
  <!-- <wx-open-launch-app
  id="launch-btn"
  appid="id432274380"
  extinfo="your-extinfo"
>
  <script type="text/wxtag-template">
    <style>.btn { padding: 12px }</style>
    <button class="btn">App内查看</button>
  </script>
</wx-open-launch-app> -->
  </template>
  <!-- <script src="http://qzonestyle.gtimg.cn/qzone/openapi/qc_loader.js" charset="utf-8" data-apptypes="qq,wechat"></script> -->
  <script>
  export default {
    name: "AlipayCom",
    components: {},
    data() {
      return {
        alipayLink: 'mqqwpa://im/chat?chat_type=wpa&uin=2793781893'
      };
    },
    computed:{
    },
    watch: {},
    mounted() {
      // console.log(this.$route.params.signLink)
      // var aaa = this.$route.params.signLink
      // window.location.href ="https://www.alipay.com/" +  this.$route.params.signLink
      // window.location.href = 'https://apps.apple.com/app/id432274380';
      // document.getElementById("jumpButton").addEventListener("click", function() {
        // window.location.href ="https://www.alipay.com/" +  aaa;
        // window.location.href = 'https://apps.apple.com/app/id432274380';
        // window.location.href = 'tel:10086';
        // window.location.href = 'mqqapi://'
        // window.location.href = 'alipays://'

      // });
    },
    methods: {
      goGo(){
        // window.location.href = 'mqqapi://'
        console.log(this.$route.params.signLink)
        window.open(
          "https://www.alipay.com/" + this.$route.params.signLink
        )
      //   window.open(
      //   'https://qm.qq.com/cgi-bin/qm/qr?k=VEMK1kRTYXaJkZnO9DCIStoJCmhqaKRb&noverify=0'//解码内容
      // )
      }
    },
  };
  </script>
  <style  scoped>
  </style>