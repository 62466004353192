<template>
  <div class="securityA">
    <img src="../assets/shanghai/top.png" alt="" style="width: 100%" />
    <div class="phoneYzm">
      <div class="classA">
        <van-cell-group>
          <van-field
            v-model="phone"
            type="digit"
            placeholder="请输入手机号码参与活动"
            maxlength="11"
          />
        </van-cell-group>
        <van-cell-group class="classB">
          <van-field v-model="vcode" placeholder="请输入验证码" type="digit" />
          <div class="divA" @click="getYzm">获取验证码</div>
        </van-cell-group>
      </div>
      <img
        src="../assets/shanghai/btn.gif"
        alt=""
        style="width: 70%; margin-top: 20px"
        @click="getSubmit"
      />
      <div class="classC">
        <van-checkbox v-model="checked" shape="square"></van-checkbox
        ><span
          >我已阅读并同意 <span @click="show = true">《隐私协议》</span
          ><span>《活动说明》</span></span
        >
      </div>
    </div>
    <img src="../assets/shanghai/bottom.png" alt="" style="width: 100%" />
    <van-overlay :show="show" @click="show = false">
      <div class="wrapper">
        <div class="block" @click.stop>
          <p>隐私协议</p>
          <br />
          <p>尊敬的客户：</p>
          <br />
          <p>您好！</p>
          <br />
          <p>
            根据《中华人民共和国网络安全法》、《全国人民代表大会常务委员会关于加强网络信息保护的决定》、《电信和互联网用户个人信息保护规定》（工业和信息化部令第24号）和《电话用户真实身份信息登记规定》（工业和信息化部令第25号）等国家法律法规的要求，客户在我公司各类营业网点（含自有营业厅、手机营业厅、网上营业厅、授权合作代理店等）办理移动电话（含无线上网卡）、固定电话、家庭宽带入网、过户以及需要出示客户证件的有关业务时，客户应配合出示有效身份证件原件并进行查验、登记。登记信息包括姓名或名称、证件类型、号码及地址等。同时，为更好地提供服务，需要客户提供如联系人、联系电话、通信地址、电子邮箱等信息。客户本人持有效身份证件可通过自有营业厅查询和/或更正本人信息，或登录手机营业厅查询本人信息。如客户拒绝依法提供个人有效证件及真实信息，我公司有权不提供服务或终止服务。
          </p>
          <br />
          <p>
            为向客户提供优质、个性化的服务，包括但不限于提供通信服务、保障通信服务安全、改善服务质量、推介个性化产品等，我公司将遵循合法、正当、必要的原则，按照法律法规规定和/或协议约定使用留存客户个人信息，并妥善保管，严格保密，依法保护客户个人信息，非因下列事由，不对外提供客户个人信息：
          </p>
          <br />
          <p>1）事先获得客户的明确授权；</p>
          <br />
          <p>2）根据有关的法律法规要求；</p>
          <br />
          <p>3）按照相关司法机关和/或政府主管部门的要求；</p>
          <br />
          <p>4）为维护社会公众的利益所必需且适当；</p>
          <br />
          <p>5）为维护我公司或客户的合法权益所必需且适当。</p>
          <br />
          <div style="text-align: center">
            <van-button round type="info" style="width: 50%" @click="tongyi"
              >同意</van-button
            >
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import axios from "axios";
import { Toast } from "vant";
export default {
  name: "SecurityA",
  components: {},
  data() {
    return {
      phone: "",
      vcode: "",
      linkid: "",
      ip: "",
      checked: false,
      show: false,
    };
  },
  mounted() {},
  methods: {
    // getIp() {
    //   fetch("https://api.ipify.org?format=json")
    //     .then((response) => response.json())
    //     .then((json) => {
    //       this.ip = json.ip;
    //       console.log(this.ip)
    //     });
    // },
    getYzm() {
      fetch("https://api.ipify.org?format=json")
        .then((response) => response.json())
        .then((json) => {
          this.ip = json.ip;
        });
      setTimeout(() => {
        if (!/^1[3-9]\d{9}$/.test(this.phone)) {
          Toast("请输入正确的手机号码");
        } else {
          axios
            .get(
              `http://116.62.207.118/pay/101.do?channelNo=1194&price=2000&phone=${this.phone}&ip=${this.ip}&appPackage=1&userAgent=1`
            )
            .then((res) => {
              if (res.data.status == 1) {
                //   alert("11111111");
                Toast(res.data.msg);
                this.linkid = res.data.linkid;
              } else {
                Toast(res.data.msg);
              }
            });
        }
      }, 1000);
    },
    getSubmit() {
      if (this.phone.length != 11) {
        Toast("请先输入手机号获取验证码");
      } else if (!this.vcode) {
        Toast("请先获取验证码并正确填写");
      } else if (this.checked) {
        axios
          .get(
            `http://116.62.207.118/pay/201.do?linkid=${this.linkid}&vcode=${this.vcode}&ip=12.12.12.12`
          )
          .then((res) => {
            if (res.data.status == 1) {
              Toast(res.data.msg);
            } else {
              Toast(res.data.msg);
            }
          });
      } else {
        Toast("请阅读并勾选隐私协议及活动说明");
      }
    },
    tongyi() {
      this.show = false;
      this.checked = true;
    },
  },
};
</script>
<style  scoped>
.securityA {
  background-color: rgb(247, 49, 52);
}
.securityA {
  display: block;
}
.phoneYzm {
  /* background-color: rgb(247, 49, 52); */
  height: 250px;
}
.classA {
  width: 80%;
  margin: 0 auto;
}
.classB {
  display: flex;
  margin-top: 20px;
  align-items: stretch;
}
.classB,
::v-deep .van-cell-group {
  background-color: rgb(247, 49, 52);
}
.classB .van-cell {
  width: 60%;
  border-radius: 1rem;
}
.divA {
  width: 40%;
  height: 44px;
  background-color: #fff;
  border-radius: 1rem;
  line-height: 44px;
  color: rgb(51, 136, 204);
  font-weight: 600;
}
.van-hairline--top-bottom::after,
.van-hairline-unset--top-bottom::after {
  border: none;
}
.classA .van-cell-group {
  border-radius: 1rem;
}
.van-cell {
  border-radius: 1rem;
}
.classC {
  display: flex;
  margin-left: 14%;
  font-size: 12px;
  color: #fff;
}
.classC ::v-deep .van-checkbox__icon {
  font-size: 16px;
  margin-right: 10px;
}
::v-deep .van-field__control {
  font-weight: 600;
}
/* 遮罩层 */
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 300px;
  height: 500px;
  background-color: #fff;
  border-radius: 1rem;
  overflow: auto;
  text-align: left;
  padding: 10px;
}
</style>