import axios from 'axios';

const instance = axios.create({
//   baseURL: 'https://h5-api.great6666.com' // 设置 API 的基础 URL
  baseURL: '/api' // 设置 API 的基础 URL
});

// 请求拦截器
instance.interceptors.request.use(
  config => {
    // 在发送请求之前做些什么
    // 可以在这里设置 token、headers 等
    return config;
  },
  error => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 响应拦截器
instance.interceptors.response.use(
  response => {
    // 对响应数据做些什么
    return response.data;
  },
  error => {
    // 对响应错误做些什么
    return Promise.reject(error);
  }
);

export default instance;