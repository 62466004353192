<template>
  <div class="hello">
    <div>
      <div class="ing101A">
        <img src="../assets/1_01.jpg" alt="" class="img101" />
        <a
          href="alipays://platformapi/startapp?url=https://openapi.alipay.com/gateway.do?alipay_root_cert_sn=687b59193f3f462dd5336e5abf83c5d8_02941eef3187dddf3d3b83462e1dfcf6&alipay_sdk=alipay-sdk-java-dynamicVersionNo&app_cert_sn=5d8835c5a351a64b4ab433cbb90022a7&app_id=2021004144603201&biz_content=%7B%22access_params%22%3A%7B%22channel%22%3A%22ALIPAYAPP%22%7D%2C%22external_agreement_no%22%3A%22S24061110304224086087%22%2C%22period_rule_params%22%3A%7B%22execute_time%22%3A%222024-06-11%22%2C%22period%22%3A30%2C%22period_type%22%3A%22DAY%22%2C%22single_amount%22%3A%220.01%22%2C%22total_amount%22%3A%221000.00%22%2C%22total_payments%22%3A1000%7D%2C%22personal_product_code%22%3A%22CYCLE_PAY_AUTH_P%22%2C%22product_code%22%3A%22GENERAL_WITHHOLDING%22%2C%22sign_scene%22%3A%22INDUSTRY%7CDEFAULT_SCENE%22%7D&charset=UTF-8&format=JSON&method=alipay.user.agreement.page.sign&notify_url=http%3A%2F%2Fqy2api.laiyue360.cn%2Fapi%2Fpay%2Forder%2Fnotify%2FS24061110304224086087&return_url=http%3A%2F%2Flocalhost%3A8081%2FLandingPage%3FclCode%3D9ae91a57%26phone%3D18770235439%3ForderId%3D101092%26orderNo%3DS24061110304224086087&sign=VDGJuCyz1AHB6oHqBSy7NWOZ3YVypyU4EDquvU4tHmmjZXpWAo4hrt1gq5olal4qy13sjfV%2BSV05squOImKI9qvwrmXM%2BYmzYaV3OL4P5xoAm%2FgG0NkifOP0RoYgujV21AgsCfa2H55a1WChj57Hmc%2BFr8WACrY9brCt5VNAgykTN974n3ch%2F68svTxtKaWGUfJaXT1AXzhMexEsal067XiFadmHdaRoZ1Y6sjMYi4B3m4De78qc%2BAPVeNLaWIjszoLP31gRD35N%2FXfSpylBaugvAvRAida%2FWUdyfl3Ym%2Fh%2BowXu%2FAB8N9gqD1FUS72Ky%2BqkRGP2gsF016oBbLY5zQ%3D%3D&sign_type=RSA2&timestamp=2024-06-11+10%3A38%3A51&version=1.0"
          >打开淘宝网页</a
        >
      </div>
      <div>
        <div>
          <div class="inp_A">
            <input
              type="number"
              placeholder="请输入您的手机号码"
              v-model="phone"
              class="inp_contro"
            />
          </div>
          <!-- <a href="https://apps.apple.com/app/id432274380">跳转到 App Store</a> -->
          <!-- <p v-if="!isValidPhoneNumber" style="color: red">
            请输入有效的手机号码
          </p> -->
          <div class="submit">
            <img
              src="../assets/5_03.png"
              alt=""
              class="submint_img"
              @click="submitA"
            />
          </div>
        </div>
      </div>
      <div><img src="../assets/1_06.jpg" alt="" class="img_1" /></div>
      <div><img src="../assets/1_07.jpg" alt="" class="img_1" /></div>
      <div><img src="../assets/1_08.png" alt="" class="img_1" /></div>
    </div>
  </div>
</template>
<script src="https://res2.wx.qq.com/open/js/jweixin-1.6.0.js"></script>
<script>
import { Toast } from "vant";
// const axios = require('axios');
import axios from "../axios";
export default {
  name: "LandingPage",
  components: {
    [Toast.name]: Toast,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      phone: "15858189561",
      isValidPhoneNumber: true,
    };
  },
  mounted() {
    console.log(this);
    console.log(window.location);
  },
  methods: {
    // validatePhoneNumber() {
    //   if (!this.phone) {
    //     this.isValidPhoneNumber = true;
    //   } else {
    //     const phoneNumberPattern = /^1[3-9]\d{9}$/; // 手机号正则表达式
    //     this.isValidPhoneNumber = phoneNumberPattern.test(this.phone);
    //   }
    // },
    submitA() {
      // alert('1111')
      // if (!this.phone) {
      //   Toast("填写正确的手机号");
      //   return;
      // } else if (this.phone.length != 11) {
      //   Toast("填写正确的手机号");
      // } else {
        let currentUrl = window.location.href;
        axios
          .post("/api/pay/order/periodicDeductionSigning", {
            phone: this.phone,
            // callbackUrl: currentUrl + "&phone=" + this.phone,
            callbackUrl: currentUrl + "&phone="+this.phone,
            channelLink: currentUrl,
            clickId: "",
          })
          .then((res) => {
            if (res.code == 200) {
              // window.location.href =
              //   "https://ulink.alipay.com/?scheme=" +
              //   encodeURIComponent(
              //     "alipays://platformapi/startapp?appId=60000157&appClearTop=false&startMultApp=YES&sign_params=",
              //     "UTF-8"
              //   ) +
              //   encodeURIComponent(
              //     encodeURIComponent(res.data.signLink, "UTF-8"),
              //     "UTF-8"
              //   );  
              window.location.href = res.data.signLink
            } else {
              Toast(res.message);
            }
          });
      // }
    },
  },
};
</script>
<style scoped>
/* *{
  margin: 0;
  padding: 0;
}
body,html{
  margin: 0 !important;
  padding: 0 !important;
  margin: 0 auto;
  max-width: 440px;
  background-color: red;
} */
.hello {
  background-color: rgb(238, 39, 14);
}
/* Chrome浏览器 */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
/* Firefox浏览器 */
input[type="number"] {
  -moz-appearance: textfield;
}
.ing101A {
  /* width: 100vw; */
}
.img101 {
  width: 100%;
  height: 100%;
}
.inp_A {
  /* font-weight: 70px; */
  line-height: 30px;
  margin: 0 auto;
  margin-bottom: 6px;
  margin-top: 10px;
  align-items: center;
  border-radius: 25px;
  padding: 10px;
  width: 80%;
  border: 1px solid rgb(217, 217, 217);
  background-color: #fff;
  text-align: center;
}

.inp_contro {
  font-size: 24px;
  font-weight: 800;
  display: block;
  box-sizing: border-box;
  width: 90%;
  min-width: 100px;
  margin: 0 auto;
  padding: 0;
  line-height: inherit;
  /* text-align: left; */
  background-color: transparent;
  border: 0;
  resize: none;
  color: black;
  outline: none;
}
.submit {
  /* width: 100vw; */
  text-align: center;
}
.submint_img {
  width: 100%;
  height: 100%;
}
.img_1 {
  width: 100%;
  height: 100%;
}
::v-deep input.inp_contro::-webkit-input-placeholder {
  /* color: red; */
  font-weight: 600;
  font-size: 20px;
}
</style>
