<template>
  <div class="" style="width: 100%; height: 100%">
    <iframe
      :src="src"
      frameborder="0"
      style="width: 100%; height: 100%"
    ></iframe>
    <div>141592385902340</div>
  </div>
</template>

<script>
export default {
  name: "FuYe",
  components: {},
  data() {
    return {
      src: "",
      loadedHtml:''
    };
  },
  watch: {},
  mounted() {
    this.src = "../../public/fuye/index.html"; 
    // this.src = "../../public/fuye/indexB.html";
    // this.src = "../assets/fuye/index.html";
    // this.src = "../fuye/index.html"
  },
  methods: {},
  created() {},
};
</script>
<style  scoped>
</style>