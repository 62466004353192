import Vue from 'vue';
import VueRouter from 'vue-router';
import RuleCom from "./components/RuleCom.vue"
import RightsHall from "./components/RightsHall.vue"
import LandingPage from "./components/LandingPage.vue"
import TestA from "./components/TestA.vue"
import TestB from "./components/TestB.vue"
import Fuye from "./components/Fuye.vue"
import Security from "./components/SHLTSecurity.vue"
import AlipayCom from "./components/AlipayCom.vue"
import AAA from "./components/AAA.vue"

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        redirect: '/LandingPage'
      },
  { path: '/RuleCom',name:"RuleCom", component: RuleCom},
   { path: '/RightsHall',name:"RightsHall", component: RightsHall},
   { path: '/LandingPage',name:"LandingPage", component: LandingPage},
   { path: '/TestA',name:"TestA", component: TestA},
   { path: '/TestB',name:"TestB", component: TestB},
   {path:'/Fuye',name:'FuYe',component:Fuye},
   {path:'/Security',name:'Security',component:Security},
   {path:'/AlipayCom',name:'AlipayCom',component:AlipayCom},
   {path:'/AAA',name:'AAA',component:AAA},
];

const router = new VueRouter({
  mode: 'history',
  routes,
   // 禁用重复导航警告
   options: {
    duplicateNavigationPolicy: 'ignore',
  }
});

export default router;
